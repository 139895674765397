<template>
  <div class="sharemModule_wrap theme_bg_white">
    <div class="sharemModule_content">
      <div class="homeIndexEntry">
        <section class="top theme_bg_red">
          <div class="nav_part">
            <div class="nav_top flex-rb">
              <div class="theme_logo"></div>
              <div class="music_logo" @click="handleOperate">
                <audioPlay ref="BGMAudioPlay" :currentBGM="currentBGM" />
              </div>
            </div>
            <div class="content"></div>
          </div>
          <div class="ren1">
            <div class="img"></div>
          </div>
          <div class="bookContent">
            <div class="ren3">
              <div class="img"></div>
            </div>
            <div class="book1">
              <div class="img"></div>
              <div class="east"></div>
            </div>
            <div class="ren2">
              <div class="img"></div>
            </div>
          </div>
          <div class="ad_card">
            <flashPlay ref="flashAnimate" :list="state.user_sliders" />
            <!-- <LongitudinalShuffling :list="state.user_sliders"></LongitudinalShuffling> -->
          </div>
        </section>
        <section class="bottom ">
          <div class="banner_part ">
            <div class="tc data_statistics theme_yellow fs12">
              <span>
                已有
                <i class="fw7 fs24">{{ state.branch_count }}</i>
                个党组织参与
              </span>
              <span>
                已有
                <i class="fw7 fs24">{{ state.user_count }}</i>
                次参与接力
              </span>
            </div>
          </div>

          <div class="operate_wrap button_ ">
            <operateBtn :path="path" :id="id" :url="url" />
          </div>

          <div class="index_bottom theme_bg_white"></div>
        </section>

        <OpenApp2 :path="path" :id="id" :url="url" />
      </div>
    </div>
  </div>
</template>
<script>
  /**分享 */
  import OpenApp2 from "@/components/openApp2";
  import linkApp from "@/utils/linkApp";
  import { Toast } from "vant";
  import comComp from "./common/index";
  import { getXuanyaniIndex } from "./hooks/useApi";
  import { wxShare, init } from "@/utils/wxShare";
  import { getIndexDate } from "./api";
  export default {
    name: "sharePart",
    components: {
      ...comComp,
      OpenApp2,
    },

    data() {
      return {
        state: {
          branch_count: "",
          user_count: "",
          user_sliders: [],
        },

        currentBGM: {
          isPlay: false,
          url: '',
          loop: true,
          isChecked: true,
          cover: require("./static/images/bgmusic.png"),
        },

        path: "openWebview",
        url: "index.html/index/entry",
        id: "index.html/index/entry",
      };
    },

    mounted() {
      this.fetchDataIndex();
      this.getIndexDate();
      this.$nextTick(() => {
        console.log(this.$refs["BGMAudioPlay"], "BGMAudioPlay");
        // this.handleOperate()
      });
    },

    methods: {
      async getIndexDate() {
        const res = await getIndexDate();
        wxShare(res.data.data.share_config.title, res.data.data.share_config.share_img, res.data.data.share_config.share_link, res.data.data.share_config.summary);
      },
      operateLinkApp() {
        const data = {
          path: this.path,
          param: this.id,
          url: this.url,
        };
        linkApp(data);
      },
      async fetchDataIndex() {
        let res = await getXuanyaniIndex();
        console.log(res.data, "fetchDataIndex");
        this.state = res.data.data;
        this.currentBGM.url = res.data.data.bg_music;
        let { user_sliders } = this.state;
        if (Array.isArray(user_sliders) && !!user_sliders) {
          this.$refs["flashAnimate"].autoCarousel();
        }
      },
      handleTab(record) {
        this.operateLinkApp();
      },

      handleOperate() {
        if (!this.currentBGM.isPlay) {
          this.$refs["BGMAudioPlay"].resumePlay();
        } else {
          this.$refs["BGMAudioPlay"].pausePlay();
        }
        this.currentBGM.isPlay = !this.currentBGM.isPlay;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "./static/css/index.scss";
</style>
